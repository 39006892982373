<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card :loading="loadingSave" color="sideBarBlue darken-3">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-select v-model="editedItem.bot_type" :items="['discord']" :menu-props="{ maxHeight: '400' }" label="Select" hint="Bot Type" persistent-hint></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isLoadingField"
                v-model="modelDiscord"
                :items="items"
                @change="updateUserId"
                :search-input.sync="search"
                color="white"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Discord User"
                placeholder="Start typing to Search"
                prepend-icon="mdi-database-search"
                return-object
              ></v-autocomplete>
              <v-text-field v-model="editedItem.user_id" label="User ID"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn :loading="loadingSave" color="blue darken-1" text @click="saveNew(editedItem)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rService from '@/services/RequestService/RequestServiceSetup';
import alertS from '@/services/AlertService/AlertService';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DialogLinks',
  data() {
    return {
      search: '',
      modelDiscord: '',
      items: [],
      dialog: false,
      loadingSave: false,
      editedIndex: -1,
      isLoadingField: false,
      editedItem: {
        bot_type: 'discord',
        user_id: ''
      }
    };
  },
  props: {
    userInfo: Object
  },
  methods: {
    updateUserId() {
      this.editedItem.user_id = this.modelDiscord.id;
    },
    close() {
      this.dialog = false;
    },

    cleanFields() {
      this.modelDiscord = '';
      this.items = [];
      this.editedItem.user_id = '';
    },

    initialize() {
      this.$emit('initialize', true);
    },

    async saveNew(item) {
      this.loadingSave = true;
      const res = await rService.post(`bot_links/insert/${this.userInfo.platform}`, item);
      if (res.success) {
        alertS.success(res.success);
        this.close();
      }
    },

    async save(item) {
      this.loadingSave = true;
      const res = await rService.post(`bot_links/update/${this.userInfo.platform}`, item);
      if (res.success) {
        this.initialize();
        this.close();
      }
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal == false) {
        this.$emit('close', true);
      }
    },
    search(val) {
      // Items have already been requested
      if (this.isLoadingField) return;

      this.isLoadingField = true;

      // Lazily load input items
      rService
        .post('discord_users', { text: val })
        .then(res => {
          this.items = res;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoadingField = false));
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Link' : 'Edit Bot';
    }
  },
  mounted() {
    this.dialog = true;
  }
};
</script>

<style></style>
